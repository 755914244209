import * as React from "react";

export const MenuRight = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width ?? 24}
		height={props.height ?? 24}
		viewBox="0 0 24 24"
		fill="none"
		{...props}
	>
		<title>Menu</title>
		<path
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M3 12h18M3 6h18M9 18h12"
		/>
	</svg>
);
