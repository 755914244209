export const navigation = [
	{
		name: "Who we are",
		// name: (
		// 	<>
		// 		<span className="text-teal-400">Who</span> we are
		// 	</>
		// ),
		href: "/who-we-are",
	},
	{
		name: "What we do",
		// <>
		// 	<span className="text-teal-400">What</span> we do
		// </>

		href: "/what-we-do",
	},
	{
		name: "Where we are",
		href: "/where-we-are",
	},
	{
		name: <>Contact us</>,
		href: "/contact",
	},
];

export const mobileNavigation = [
	{ name: "Home", href: "/" },
	{ name: "Who we are", href: "/who-we-are" },
	{ name: "What we do", href: "/what-we-do" },
	{
		name: "Where we are",
		href: "/where-we-are",
	},
	{ name: "Contact us", href: "/contact" },
];
