import * as React from "react";

export const Logo = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		viewBox="0 0 1066 339"
		{...props}
	>
		<title>BREvents Logo</title>
		<path
			fill="#cccccc"
			d="M287.326 163.332a1637.174 1637.174 0 0 1-5.509 3.437c2.715 1.255 5.036 2.315 7.345 3.399 11.86 5.561 18.317 14.6 17.758 27.994-.558 13.382-7.335 22.806-19.604 27.5-6.878 2.632-14.415 4.563-21.728 4.834-18.291.678-36.624.23-54.94.22-.643-.001-1.286-.17-2.307-.313V107.808c1.149-.148 2.403-.447 3.658-.449 16.318-.029 32.648-.385 48.948.167 6.69.227 13.582 1.676 19.907 3.914 10.395 3.677 17.476 11.135 18.966 22.449 1.57 11.916-2.029 22.045-12.494 29.443m3.793 24.39c-3.663-7.197-10.06-11.138-17.58-11.61-15.76-.99-31.587-.946-47.388-1.29-1.272-.027-2.55.225-3.918.357v42.61c15.534 0 30.67.155 45.797-.113 3.568-.064 7.252-1.173 10.64-2.448 12.16-4.579 16.73-14.404 12.45-27.506m-47.62-67.647h-21.21v41.937c14.12 0 27.79.422 41.418-.166 7.606-.33 14.866-3.165 19.144-10.163 7.972-13.042 1.132-27.86-14.467-30.463-7.808-1.303-15.914-.82-24.885-1.145zm173.573-4.213c12.402 9.22 16.439 21.41 13.804 35.881-3.176 17.44-16.062 24.578-32.266 28.831l37.612 50.083c-5.964 0-11.024.173-16.054-.132-1.135-.069-2.368-1.652-3.222-2.789-10.3-13.708-20.588-27.427-30.748-41.239-1.956-2.659-4.07-3.802-7.467-3.718-9.785.242-19.579.086-29.813.086-.118 2.272-.285 4.041-.289 5.81-.023 12.496-.105 24.993.049 37.486.042 3.41-.937 4.73-4.521 4.643-11.907-.29-9.897 1.215-9.923-9.655-.086-35.987.045-71.974-.126-107.96-.022-4.521 1.105-6.041 5.81-5.954 15.489.286 30.986.123 46.48.113 10.89-.007 21.133 2.321 30.674 8.514m-33.61 54.308c6.183-.988 12.656-1.138 18.481-3.158 8.923-3.094 14.67-9.655 15.276-19.598.933-15.295-8.099-25.51-24.055-26.362-13.6-.726-27.249-.503-40.874-.813-2.938-.067-4.165.83-4.138 3.959.126 14.468.059 28.938.103 43.408.002.757.393 1.513.684 2.562 11.278 0 22.406 0 34.523.002z"
		/>
		<path
			fill="#03A2B8"
			d="M591.838 201.303c-11.102-25.543-22.135-50.702-32.936-75.96-1.62-3.79-3.679-5.08-7.8-5.054-27.817.183-55.637.09-83.456.087h-6.25v-12.942h5.387c31.15.001 62.302.095 93.451-.107 4.257-.027 6.136 1.505 7.692 5.224 13.369 31.942 26.907 63.814 40.41 95.7.684 1.614 1.514 3.166 2.762 5.753 2.833-6.703 5.32-12.422 7.678-18.194 6.863-16.805 13.82-33.573 20.406-50.486 1.708-4.386 3.921-6.477 8.716-5.761 2.416.36 4.931.063 8.306.063-2.481 5.898-4.662 11.182-6.92 16.433-10.132 23.56-20.408 47.058-30.315 70.712-1.734 4.14-4.196 4.778-8.033 4.677-3.672-.097-6.531-.133-8.173-4.526-3.202-8.568-7.145-16.86-10.925-25.619z"
		/>
		<path
			fill="#03A2B8"
			d="M747.341 217.523c-22.22 24.301-64.171 19.12-77.26-12.885-8.411-20.572-1.751-46.766 15.326-59.042 25.278-18.17 58.737-4.683 65.411 26.518 1.2 5.607 1.365 11.434 2.102 18.006H681.4c-1.395 9.474 5.197 21.365 15.26 26.477 16.492 8.375 30.948 3.818 44.071-8.565 2.286 2.034 4.616 3.88 6.616 6.03.568.612.173 2.117-.006 3.461m-63.324-50.839-4.176 12.93h58.396c-.427-7.984-2.673-14.825-7.556-20.618-13.158-15.607-35.645-12.176-46.664 7.688z"
		/>
		<path
			fill="#03A2B8"
			d="M795.211 160.361c-4.21 5.97-5.635 12.349-5.613 19.192.047 14.996.004 29.993-.002 44.99-.001 1.79 0 3.58 0 5.743h-13.241v-90.394h13.191v13.704c2.743-2.623 4.648-4.352 6.45-6.182 10.159-10.32 22.647-11.6 35.665-8.117 12.758 3.413 21.492 12.535 22.244 25.484 1.26 21.682.344 43.49.344 65.86-3.159 0-6.618.054-10.074-.015-3.522-.071-2.633-2.823-2.64-4.79-.057-15.83.105-31.662-.124-47.489-.065-4.433-.496-9.106-1.977-13.233-6.721-18.725-30.194-17.936-40.926-8.32-1.111.996-2.06 2.174-3.297 3.567z"
		/>
		<path
			fill="#03A2B8"
			d="M475.32 176.542c-.154 12.447-.077 24.441-.307 36.43-.074 3.862 1.48 4.746 5.025 4.726 21.487-.118 42.975-.053 64.463-.049h6.15v12.684h-89.265v-67.808h80.384v12.145c-1.766.087-3.526.246-5.285.248-18.49.017-36.98-.024-55.469.06-1.863.008-3.724.722-5.697 1.564z"
		/>
		<path
			fill="#03A2B8"
			d="M964.79 162.1c-.178 5.717 3.078 8.86 7.378 10.665 6.426 2.697 13.12 4.749 19.667 7.166 4.05 1.495 8.178 2.878 12.033 4.792 19.242 9.555 15.197 32.455 2.598 41.192-9.404 6.521-19.8 7.398-30.61 5.635-10.486-1.711-19.983-5.84-28.724-12.651l6.61-9.485c3.324 1.916 6.403 3.8 9.58 5.5 7.177 3.844 14.832 5.989 23.009 5.646 2.785-.117 5.671-.601 8.281-1.55 5.405-1.965 8.914-5.77 9.348-11.776.421-5.846-2.949-9.63-7.797-11.62-7.677-3.15-15.654-5.568-23.482-8.355-2.66-.946-5.353-1.878-7.861-3.15-7.934-4.025-13.35-9.907-13.39-19.4-.04-9.864 4.271-17.336 12.823-22.16 9.688-5.464 20.137-5.77 30.524-2.919 6.92 1.9 13.5 5.035 20.756 7.817l-6.213 10.145c-6.78-2.45-12.99-5.422-19.537-6.823-4.862-1.04-10.3-.747-15.224.236-5.332 1.065-9.052 4.793-9.769 11.095z"
		/>
		<path
			fill="#cccccc"
			d="M64.967 168.998c-.002 4.827.121 9.16-.084 13.478-.059 1.24-.826 3.278-1.673 3.517-6.31 1.78-12.722 3.2-19.894 4.923v-6.094c.001-35.285.088-70.57-.098-105.854-.025-4.764 1.213-6.554 6.114-6.144 4.93.412 9.92.09 15.635.09v96.084z"
		/>
		<path
			fill="#03A2B8"
			d="M902.269 153.463c-.12 16.44-.154 32.422-.15 48.404.004 14.43 7.2 20.188 21.411 17.104 2.083-.452 4.099-1.213 6.14-1.83 3.049 9.173 1.177 12.873-7.97 14.236-4.704.701-9.69.761-14.383.036-10.806-1.671-17.746-8.878-18.215-20.003-.707-16.784-.455-33.61-.565-50.419-.059-8.935-.015-8.93-8.9-9.113-.974-.02-1.945-.16-3.304-.28v-11.594c1.112-.13 2.231-.362 3.354-.378 9.923-.142 8.74 1.368 8.82-9.25.041-5.326.054-10.653.112-15.98.005-.467.254-.932.493-1.751h13.013c0 7.29.243 14.543-.092 21.769-.198 4.268 1.314 5.407 5.383 5.253 7.617-.288 15.252-.083 23.203-.083v12.278c-7.167 0-14.403-.087-21.634.052-2.21.043-4.41.71-6.716 1.549z"
		/>
		<path
			fill="#cccccc"
			d="M146.268 252v17.767H125.01v-5.496c0-28.49.064-56.978-.083-85.467-.018-3.609 1.157-5.67 4.149-7.65 5.622-3.72 10.868-8.006 17.193-12.75V252zM125.014 75.28c.82-1.092 1.615-2.23 2.44-2.251 5.961-.154 11.93-.083 18.465-.083.13 1.864.335 3.44.337 5.017.022 19.454.114 38.908-.12 58.36-.027 2.286-1.31 5.203-3.005 6.707-5.385 4.777-11.24 9.023-18.128 14.428 0-28.344 0-55.024.01-82.177zM56.946 270.15H43.659c-.132-1.868-.323-3.32-.324-4.773-.019-20.666.089-41.334-.097-61.999-.037-4.103 1.167-5.456 5.315-5.549 5.257-.117 10.494-1.134 16.092-1.806v74.128h-7.7z"
		/>
		<path
			fill="#03A2B8"
			d="M83.77 277c.002-26.994.103-53.489-.104-79.981-.031-4.067 1.19-6.001 5.032-7.097 5.545-1.583 10.95-3.655 17.1-5.751.155 2.191.383 3.93.384 5.667.027 37.492-.058 74.984.14 112.474.025 4.915-1.521 6.5-6.263 6.076-3.968-.355-8-.248-11.988-.033-3.319.179-4.392-1.17-4.346-4.362.128-8.83.043-17.662.044-26.993m2.173-75.412v104.467c4.631 0 8.687-.3 12.678.08 4.525.428 5.558-1.31 5.538-5.634-.166-35.97-.09-71.94-.107-107.91 0-1.74-.18-3.48-.31-5.81-3.948 1.345-7.214 2.403-10.44 3.57-8.148 2.95-7.278.857-7.359 11.237z"
		/>
		<path
			fill="#03A2B8"
			d="m95.144 174.183-10.995 5.857c-.172-3.119-.354-4.885-.355-6.65-.026-43.893-.036-87.786-.04-131.68-.001-6.964.02-7.044 7.21-6.941 4.77.069 9.536.407 14.167.966-.48.592-.822.843-1.627 1.141-4.287.052-8.148.385-11.928-.024-4.847-.524-5.735 1.56-5.72 5.944.144 42.571.084 85.143.102 127.714 0 1.728.193 3.456.302 5.28 3.288-.754 5.947-1.362 8.675-1.88l.209.273z"
		/>
		<path
			fill="#03A2B8"
			d="M103.967 36.828c.342-.25.684-.5 1.244-.761.483 1.246.979 2.502.98 3.759.033 42.122.02 84.245-.004 126.368 0 .813-.234 1.626-.936 2.638-.786.175-.995.152-1.203-.336l-.08-131.668z"
		/>
		<path
			fill="#03A2B8"
			d="M104.047 168.96c.21.024.418.047.939.114-2.879 1.736-6.07 3.43-9.55 5.116-.292-.007-.432-.189-.279-.533 3.065-1.795 5.978-3.246 8.89-4.697z"
		/>
		<path
			fill="#03A2B8"
			d="M85.942 201.106c.08-9.898-.79-7.805 7.359-10.754 3.226-1.168 6.492-2.225 10.44-3.572.13 2.331.31 4.071.31 5.812.017 35.97-.06 71.94.107 107.909.02 4.324-1.013 6.063-5.538 5.633-3.992-.378-8.047-.08-12.678-.08V201.107z"
		/>
		<path
			fill="#03A2B8"
			d="M104.048 168.496c-2.913 1.915-5.826 3.366-8.96 5.07-2.881.862-5.54 1.47-8.828 2.223-.11-1.823-.302-3.55-.302-5.279-.018-42.571.042-85.143-.102-127.713-.015-4.384.873-6.469 5.72-5.944 3.78.408 7.64.075 11.928.024.49 43.686.518 87.42.544 131.619z"
		/>
	</svg>
);
