import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Logo } from "./Logo";
import classNames from "classnames";
import { MenuRight } from "./icons/MenuRight";
import { navigation, mobileNavigation } from "../routes";
import { Phone } from "./icons/Phone";
import { Email } from "./icons/Email";
import { RoughNotation } from "react-rough-notation";

const isActive = (url: string, href: string): boolean => {
	const activeUrl = new URL(url);
	return activeUrl.pathname === href || activeUrl.pathname === `${href}/`;
};

export interface NavBarProps {
	url: string;
}

export const NavBar: React.FC<NavBarProps> = ({ url }) => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	const headerClasses = classNames(
		"transition-all top-0 py-2 sm:py-0 left-0 w-full z-10",
		{
			"fixed bg-gradient-to-b from-stone-800 to-stone-800  shadow-xl ":
				isScrolled === true,
			"absolute bg-transparent": isScrolled === false,
		},
	);

	const dialogPanelClasses = classNames(
		"transition ease-in-out delay-75 fixed px-8 inset-y-0 right-0 z-10 w-full overflow-y-auto  pb-6   sm:ring-1 sm:ring-gray-900/10",
		{
			"bg-stone-300": mobileMenuOpen === false,
			"bg-gradient-to-b from-stone-900 via-stone-800 to-stone-900":
				mobileMenuOpen === true,
		},
	);

	useEffect(() => {
		const onScroll = () => {
			const el: HTMLElement | null = document.querySelector("header");
			if (el) {
				if (mobileMenuOpen === true) {
					return;
				}
				if (window.scrollY > 0) {
					setIsScrolled(true);
				} else {
					setIsScrolled(false);
				}
			}
		};
		window.addEventListener("scroll", onScroll);
		onScroll();
		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, [mobileMenuOpen]);

	return (
		<header className={headerClasses}>
			<nav
				className="flex mx-auto max-w-7xl px-8   items-center justify-between "
				aria-label="Global"
			>
				<div className="flex lg:flex-1">
					<a href="/" className="cursor-pointer -m-1.5 p-1.5">
						<span className="sr-only">BREvents</span>
						<Logo className="h-12 w-36 sm:h-16 sm:w-40" />
					</a>
				</div>
				<div className="flex lg:hidden">
					<button
						type="button"
						className="-m-2.5  inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
						onClick={(evt) => setMobileMenuOpen(true)}
					>
						<span className="sr-only">Open main menu</span>
						<MenuRight
							className="h-6 w-6 sm:h-8 sm:w-8 text-white"
							aria-hidden="true"
						/>
					</button>
				</div>
				<div className="hidden lg:flex lg:gap-x-12">
					{navigation.map((item) => (
						<RoughNotation
							key={item.href}
							show={isActive(url, item.href)}
							type="underline"
							color="#03a2b7"
							padding={[1, 10]}
							strokeWidth={2}
						>
							<a
								href={item.href}
								className="text-md px-2  transition-all  tracking-wider uppercase rounded-md font-bold  leading-8 text-white"
							>
								{item.name}
							</a>
						</RoughNotation>
					))}
				</div>
			</nav>
			<Transition.Root show={mobileMenuOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-20"
					onClose={() => setMobileMenuOpen(false)}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-stone-600 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="fixed inset-y-0 right-0 flex max-w-full ">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className={dialogPanelClasses}>
										<div className="flex flex-col   items-center justify-center absolute -top-16 bottom-0 left-0 right-0  divide-y divide-gray-500/10">
											<div className="space-y-2 pb-8  flex flex-col items-center gap-8">
												{mobileNavigation.map((item, index) => (
													<a
														key={item.href}
														href={item.href}
														style={{
															animationDelay: `${(index + 1) * 200}ms`,
														}}
														className={`${
															isActive(url, item.href) ? "bg-teal-700/75" : ""
														} hover:bg-teal-700/50 bg-teal-700/0 transition-all  lg:rounded-md   rounded-full px-4 lg:px-2 focus-visible:border-0 focus-visible:outline-0 cursor-pointer fadeInUp text-md py-2 tracking-wider uppercase text-2xl font-medium leading-8 text-white`}
													>
														{item.name}
													</a>
												))}
											</div>
										</div>
										<div className="flex flex-1 relative  items-center justify-between py-2 ">
											<a href="/" className="cursor-pointer -m-1.5 p-1.5">
												<span className="sr-only">BREvents</span>
												<Logo width={145} height={45} />
											</a>
											<button
												type="button"
												className="inline-flex -m-2.5 rounded-md p-2.5 text-gray-700"
												onClick={() => setMobileMenuOpen(false)}
											>
												<span className="sr-only">Close menu</span>
												<XMarkIcon
													className="h-6 w-6 sm:h-8 sm:w-8  text-white"
													aria-hidden="true"
												/>
											</button>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</header>
	);
};
